import React from 'react';
import {hasValue, isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import {useTranslation} from "react-i18next";
import {getProgressBarColor} from "../../../../../components/common/progressbar/progressbarHelper";
import BreedInfoBlockDetails from "../../breedinfo/BreedInfoBlockDetails";
import BreedInfoBlockPreview from "../../breedinfo/BreedInfoBlockPreview";
import BreedInfoBlock from "../../breedinfo/BreedInfoBlock";
import InformationBlock from "../../../../../components/common/ui/text/informationblock/InformationBlock";

/**
 * Блок с информацией о породе
 */
const BreedCareBlock = ({data}) => {
    const {t} = useTranslation();

    function getCareInfoMap() {
        const detailsInfoMap = [];

        if (hasValue(data.description)) {
            detailsInfoMap.push({
                title: 'Подробно про уход',
                value: data.description,
                hasValue: true
            })
        }
        return detailsInfoMap;
    }

    if (isEmptyOrNull(data)) {
        return null;
    }
    return (
        <BreedInfoBlock>
            <BreedInfoBlockPreview title={data.sectionTitle}>
                <div className="breed-info-block__aggregated-mark">
                    <div className="progress-bar wide">
                        <div className={`progress ${getProgressBarColor(data.difficulty)}`}>
                            <span>{`${t('difficulty.' + data.difficulty)}`}</span>
                        </div>
                    </div>
                    <div className="aggregated-mark__mark-comment">
                        <span>{data.shortInfo}</span>
                    </div>
                </div>
            </BreedInfoBlockPreview>
            <BreedInfoBlockDetails>
                <div className="breed-info-block__params">
                    <div className="breed-info-block__params-interlayer">
                        {data.properties.map((param, index) => {
                            return <div key={index} className="breed-info-block__params-param">
                                <div className="param-label">
                                    <span>{param.name}</span>
                                </div>
                                <div className="progress-bar thin">
                                    <div className={`progress ${getProgressBarColor(param.data.difficulty)}`}>
                                        <span>{param.data.name}</span>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <div className="details-summary">
                    {/*<span>{data.description}</span>*/}
                    <InformationBlock infoMap={getCareInfoMap()} withBorders={false}/>
                </div>
            </BreedInfoBlockDetails>
        </BreedInfoBlock>
    );
};

export default BreedCareBlock;