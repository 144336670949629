import React from 'react';
import FormItem from "./FormItem";
import TextArea from "../TextArea";
import {ACTION_ADD} from "../../../helper/formConstants";
import {storeItem} from "../../../helper/formHelper";
import FormError from "./FormError";

/**
 * Компонент формы "text area"
 * надстройка над компонентом TextArea
 *
 * Помимо самого инпута снизу выводит текст ошибки если она есть.
 */
const TextAreaFormItem = ({
                              fieldName, placeholder, register, tabIndex, action, hasValue = false,
                              errors = null
                          }) => {
    return (
        <FormItem>
            <TextArea placeholder={placeholder} register={register}
                      fieldName={fieldName} storeFunction={action === ACTION_ADD ? storeItem : null}
                      tabIndex={tabIndex} hasValue={hasValue}/>
            {errors == null ? null : errors[fieldName] ? <FormError title={errors[fieldName].message}/> : null}
        </FormItem>
    );
};

export default TextAreaFormItem;