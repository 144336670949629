import React, {useState} from 'react';
import {EMPTY} from "../../../../app/const/appConst";

/**
 * Раскрывающийся блок с детальной информацией в блоке о породе
 */
const BreedInfoBlockDetails = ({children}) => {
    const DETAILS = "Детали";
    const SHOW_LESS = "Свернуть";
    const LABEL_DELAY = 200;
    const [expanded, setExpanded] = useState(false);
    const [detailsLabel, setDetailsLabel] = useState(DETAILS);

    function toggleDetails() {
        setExpanded(!expanded);
        // Задержка перед изменением метки
        setTimeout(() => {
            setDetailsLabel(detailsLabel === DETAILS ? SHOW_LESS : DETAILS);
        }, LABEL_DELAY);
    }

    return (
        <>
            <div className={`breed-info-block__details ${expanded ? 'expanded' : EMPTY}`}>
                {children}
            </div>
            <div className="read-more-container" onClick={() => toggleDetails()}>
                <p className="read-more-text">{detailsLabel}</p>
            </div>
        </>
    );
};

export default BreedInfoBlockDetails;
