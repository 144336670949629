import React from 'react';
import {EMPTY} from "../../../../../../app/const/appConst";

/**
 * Список чекбоксов на вход которому подаются Enum'ы
 */
const EnumCheckboxSet = ({enumValues, fieldName, selectedItems, register, handleCheckboxChange}) => {
    return (
        <>
            {enumValues.map(option => (
                <div key={option.id}>
                    <label className={`custom-checkbox ${selectedItems.has(option.id) ? 'green' : EMPTY}`}>
                        <input className="custom-checkbox-input"
                               type="checkbox"
                               value={option.id}
                               {...register(`${fieldName}.${option.id}`)}
                               checked={selectedItems.has(option.id)}
                               onChange={() => handleCheckboxChange(option.id)}
                        />
                        <span className="custom-checkbox-box"></span>
                        {option.name}
                    </label>
                </div>
            ))}
        </>
    );
};

export default EnumCheckboxSet;