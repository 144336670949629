import React, {useEffect, useState} from 'react';
import DictionaryContent from "../../../../../components/adminpanel/DictionaryContent";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import FormError from "../../../../../components/common/form/parts/inputs/wrappers/FormError";
import {useTranslation} from "react-i18next";
import CountryForm from "./CountryForm";
import PageTitle from "../../../../../components/common/PageTitle";
import {ACTION_ADD, ACTION_UPDATE} from "../../../../../components/common/form/helper/formConstants";
import {getAllRecordDataById} from "../../../../../service/dictionaryService";
import {COUNTRY_METADATA} from "../geoFormsConstants";

/**
 * Добавление/изменение данных о переводах по странам
 */
const CountryTranslations = () => {
    const params = useParams();
    const token = useSelector(state => state.userReducer.token);
    const {t} = useTranslation();
    const [country, setCountry] = useState({});
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function getCountryInfo() {
        const response = getAllRecordDataById(Number(params.id), COUNTRY_METADATA.backControllerName, token);
        response.then((resp) => {
            const data = resp.data.data;
            setCountry(data);
        }, (error) => {
            setError(true)
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        })
    }

    function getAction() {
        return isNaN(Number(params.id))? ACTION_ADD : ACTION_UPDATE;
    }

    function getPageTitle() {
        return t(COUNTRY_METADATA.title) + '. ' + (ACTION_ADD === getAction() ?
            t('page.actions.add') : t('page.actions.edit'));
    }

    useEffect(() => {
        if (ACTION_UPDATE === getAction()) {
            getCountryInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <DictionaryContent backrefPath={`/panel/dictionaries/${COUNTRY_METADATA.backControllerName}`}
                           backrefTitle={t('adminPanel.dictionaries.common.backToDictionary')}>
            {error ? <FormError title={errorMessage}/> : null}
            <PageTitle title={getPageTitle()}/>
            <CountryForm action={getAction()} country={country}/>
        </DictionaryContent>
    );
};

export default CountryTranslations;