import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import DictionaryContent from "../../../../../components/adminpanel/DictionaryContent";
import Spinner from "../../../../../components/common/Spinner";
import CountriesTable from "./table/CountriesTable";
import {getAllRecordsInCurrentTranslation} from "../../../../../service/dictionaryService";
import {COUNTRY_METADATA} from "../geoFormsConstants";
import {EMPTY} from "../../../../../app/const/appConst";

/**
 * Справочник "Страны"
 */
const Countries = () => {
    const {t, i18n} = useTranslation();

    const [countries, setCountries] = useState([]);
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        const response = getAllRecordsInCurrentTranslation(COUNTRY_METADATA.backControllerName);
        response.then((resp) => {
            setErrorMessage('')
            setCountries(resp.data.data)
            setIsLoading(false)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
            setIsLoading(false)
        });

    }, [i18n.language])

    return (
        <DictionaryContent title={t(COUNTRY_METADATA.title)} showControlPanel={true}
                           enableAddingElements={true} dictionaryBackendName={COUNTRY_METADATA.backControllerName}>
            {loading
                ? <Spinner/>
                : <CountriesTable countries={countries} error={errorMessage}/>
            }
        </DictionaryContent>
    );
};

export default Countries;