import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {EMPTY} from "../../../app/const/appConst";
import {hasValue, isEmptyOrNull} from "../../../app/helper/commonHelper";
import {
    ACTION_ADD,
    ACTION_UPDATE,
    ENUM,
    ENUM_SET,
    FORM_CHECKBOX_SET,
    FORM_INPUT,
    FORM_NUMBER_INPUT,
    FORM_RADIO_SET,
    FORM_TEXTAREA,
    HIDDEN,
    NUMBER,
    STRING
} from "../form/helper/formConstants";
import {getAllRecordDataById, getPossibleValues, saveRecord} from "../../../service/dictionaryService";
import {useSelector} from "react-redux";
import Content from "../Content";
import TableNoResults from "../table/TableNoResults";
import HiddenInput from "../form/parts/inputs/HiddenInput";
import TextInputFormItem from "../form/parts/inputs/wrappers/TextInputFormItem";
import {
    clearSessionDataForDictionary,
    getDefaultValueForType,
    getStoredItem,
    isFieldFilled,
    storeItem
} from "../form/helper/formHelper";
import SubmitSection from "../form/parts/controlsection/SubmitSection";
import Form from "../form/abstract/Form";
import {useForm} from "react-hook-form";
import {getLanguageShortName} from "../../../utils/langUtils";
import TextAreaFormItem from "../form/parts/inputs/wrappers/TextAreaFormItem";
import NumberInputFormItem from "../form/parts/inputs/wrappers/NumberInputFormItem";
import {get} from "lodash";
import RadioSetFormItem from "../form/parts/inputs/wrappers/RadioSetFormItem";
import CheckboxSetFormItem from "../form/parts/inputs/wrappers/CheckboxSetFormItem";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {validateNameRegEx} from "../../../utils/stringutils";
import DictionaryHeader from "./DictionaryHeader";

/**
 * Страница для добавления/редактирования элемента справочника
 * @param metadata - описание справочника
 * @param dictionaryEditingItem - редактируемый элемент справочника, м.б. null,
 * если происходит добавление нового элемента
 */
const DictionaryTranslations = ({metadata}) => {
        const params = useParams();
        const navigate = useNavigate();
        const {t, i18n} = useTranslation();
        const location = useLocation();
        const token = useSelector(state => state.userReducer.token);
        const [dictionaryItem, setDictionaryItem] = useState({});
        const [action, setAction] = useState(EMPTY);
        const [errorMessage, setErrorMessage] = useState(EMPTY);
        const [error, setError] = useState(false);
        const [disabledSubmit, setDisabledSubmit] = useState(false);
        const [loading, setIsLoading] = useState(false);
        const [possibleValues, setPossibleValues] = useState({});
        const [pageTitle, setPageTitle] = useState(EMPTY);
        const {
            register,
            formState: {errors},
            handleSubmit,
            setValue,
            getValues
        } = useForm({
            resolver: yupResolver(createValidationScheme(metadata.fields, t)),
            mode: "onBlur",
            reValidateMode: "onBlur"
        })

        const language = getLanguageShortName(i18n.language);

        //сделаем пока примитивную валидацию
        function createValidationScheme(fields, t) {
            const schemaFields = {};

            if (isEmptyOrNull(fields)) {
                return yup.object().shape(schemaFields);
            }
            Object.entries(fields).filter(([, value]) => value.hasOwnProperty('validation'))
                .forEach(([, field]) => {
                    let validator;
                    if (NUMBER === field.type) {
                        validator = yup.number()
                    } else if (STRING === field.type) {
                        validator = yup.string()
                    } else {
                        return;
                    }
                    Object.entries(field.validation).forEach(([validatorTitle, validatorValue]) => {
                        if ('required' === validatorTitle) {
                            validator = validator.required(t("form.common.errors.fieldRequired"))
                        }
                        if ('exactLength' === validatorTitle) {
                            validator = validator.length(Number(validatorValue),
                                `Должно быть ровно ${Number(validatorValue)} символов`)
                        }
                        if ('email' === validatorTitle) {
                            validator = validator.email(t("form.common.errors.emailIncorrectFormat"))
                        }
                        if ('minLength' === validatorTitle) {
                            validator = validator.min(Number(validatorValue),
                                `Необходимо минимум ${Number(validatorValue)} символов`)
                        }
                        if ('maxLength' === validatorTitle) {
                            validator = validator.max(Number(validatorValue),
                                `Максимально должно быть ${Number(validatorValue)} символов`)
                        }
                        if ('nameFormat' === validatorTitle) {
                            validator = validator.matches(validateNameRegEx);
                        }
                    })
                    schemaFields[field.title] = validator;
                });
            return yup.object().shape(schemaFields);
        }

        function initAdd() {
            Object.entries(metadata.fields)
                .filter(([, field]) => field.hasOwnProperty('formElement'))
                .forEach(([, field]) => {
                    setValue(field.title, getStoredItem(field.title, field.type))
                })
        }

        function initUpdate(item) {
            if (isEmptyOrNull(item)) {
                return;
            }
            Object.entries(metadata.fields)
                .filter(([, field]) => field.hasOwnProperty('formElement'))
                .forEach(([, field]) => {
                    if (ENUM === field.type) {
                        const enumObj = get(item, field.objectPath, getDefaultValueForType(field.type))
                        setValue(field.title, enumObj.id)
                    } else if (ENUM_SET === field.type) {
                        const setOfElements = new Set(get(item, field.objectPath, new Set()))
                        setOfElements.forEach(el => {
                            setValue(`${field.title}.${el.id}`, el.id)
                        })
                    } else {
                        setValue(field.title, get(item, field.objectPath, getDefaultValueForType(field.type)));
                    }
                })
        }


        function getDictionaryElement() {
            setIsLoading(true);
            if (isEmptyOrNull(params.id)) {
                setAction(ACTION_ADD);
                setPageTitle(`. ${t('page.actions.add')}`)
                initAdd();
            } else {
                setPageTitle(`. ${t('page.actions.edit')}`)
                setAction(ACTION_UPDATE);

                // в компонент может передаться редактируемый элемент с формы просмотра элемента справочника.
                // в этом случае его не нужно запрашивать повторно
                if (isEmptyOrNull(location.state) || location.state.dictionaryEditingItem) {
                    const response = getAllRecordDataById(Number(params.id), metadata.backControllerName, token);
                    response.then((resp) => {
                        const data = resp.data.data;
                        setDictionaryItem(data);
                        initUpdate(data);
                    }, (error) => {
                        setErrorMessage(error.response?.data?.messages ?
                            error.response?.data?.messages?.ERROR[0] : error.message)
                    })
                } else {
                    setDictionaryItem(location.state.dictionaryEditingItem);
                }
            }
            setIsLoading(false);
        }

        function loadPossibleValues(fields) {
            setIsLoading(true);
            const fieldsNeedValues = Object.entries(fields)
                .filter(([, value]) => value.hasOwnProperty('optionsSource'));

            const values = Promise.all(fieldsNeedValues.map(async ([key, value]) => {
                const options = await getPossibleValues(value.optionsSource);
                return {key, options: options.data.data};
            }));

            values.then((resp) => {
                const newPossibleValues = resp.reduce((acc, {key, options}) => {
                    acc[key] = options;
                    return acc;
                }, {});
                setPossibleValues(newPossibleValues);
            }, () => {
                setErrorMessage("Произошла ошибка при получении возможных значений к полям справочника")
            })
            setIsLoading(false);
        }

        function getBackToUrl() {
            if (ACTION_UPDATE === action && hasValue(params.id)) {
                return `/${metadata.backControllerName}/${params.id}`
            }
            return `/${metadata.backControllerName}`;
        }

        function initEmptyDto(fields, formData) {
            const result = {}; // Инициализация пустого результата
            // Проходим по всем значениям метаданных поля с использованием Object.values()
            Object.values(fields).forEach(field => {
                const value = formData[field.title]
                if (isEmptyOrNull(value)) {
                    return;
                }
                // Разбиваем строку objectPath на массив ключей (возможна вложенность)
                const keys = field.objectPath.split('.');
                let current = result; // Указатель на текущий уровень в результирующем объекте

                // Проходим по каждому ключу в массиве keys
                keys.forEach((key, index) => {
                    // Если ключ последний в массиве (индекс равен длине массива - 1)
                    if (index === keys.length - 1) {
                        // Устанавливаем значение в результате для последнего ключа
                        // Проверяем тип поля для инициализации соответствующим значением
                        if (field.type === 'NUMBER') {
                            current[key] = 0; // Инициализируем числовое значение как 0
                        } else if (field.type === 'STRING') {
                            current[key] = ''; // Инициализируем строковое значение как пустую строку
                        } else {
                            current[key] = null; // Для всех других типов инициализируем null
                        }
                    } else {
                        // Если ключ не последний, создаем вложенный объект, если он не существует
                        if (!current[key]) {
                            current[key] = {};
                        }
                        // Переходим на следующий уровень вложенности
                        current = current[key];
                    }
                });
            });
            return result;
        }

        function getValue(field, value) {
            if (isEmptyOrNull(value)) {
                return null;
            }
            if (ENUM === field.type) {
                return {id: value}
            }
            if (ENUM_SET === field.type) {
                const result = [];
                Object.entries(value)
                    .filter(([, val]) => val !== false)
                    .map(([key]) => key)
                    .forEach(el => result.push({id: el}));
                return result;
            }
            return value;
        }

        function fillData(fields, formData, result, language) {
            Object.values(fields).forEach(field => {
                const path = field.objectPath.split('.');
                const value = getValue(field, formData[field.title]);

                if (isEmptyOrNull(value)) {
                    return;
                }
                let current = result;

                for (let i = 0; i < path.length - 1; i++) {
                    const key = path[i];
                    if (!current[key]) {
                        current[key] = {};
                    }
                    current = current[key];
                }
                current[path[path.length - 1]] = value;
            });
            fillLanguageData(result, language)
        }

        function fillLanguageData(result, language) {
            result.language = language;
            if (hasValue(result.translation)) {
                result.translation.locale = language;
            }
        }

        function formDto(fields, formData, language) {
            const result = initEmptyDto(fields, formData);
            fillData(fields, formData, result, language)
            return result;
        }

        function getOnBlurFunction() {
            if (EMPTY === action || ACTION_UPDATE === action) {
                return null
            }

            return storeItem
        }

        /**
         * Обработка события отправки формы
         */
        function submitForm(formData) {
            setDisabledSubmit(true);
            setIsLoading(true)
            const dto = formDto(metadata.fields, formData, language);
            console.log('dto to store', dto)
            const resp = saveRecord(dto, metadata.backControllerName, token)
            resp.then(
                () => {
                    clearSessionDataForDictionary(metadata.fields, action);
                    setError(false);
                    setIsLoading(false);
                    navigate(getBackToUrl());
                },
                (error) => {
                    setIsLoading(false)
                    setError(true)
                    setDisabledSubmit(false);
                    setErrorMessage(error.response?.data?.messages ?
                        error.response?.data?.messages?.ERROR[0] : error.message)
                })
        }

        useEffect(() => {
            getDictionaryElement();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            //зачищаем сообщение об ошибках
            setErrorMessage(EMPTY);
            if (EMPTY === action) {
                return;
            }
            //если при обновлении элемента справочника мы не смогли получить элемент
            if (ACTION_UPDATE === action && isEmptyOrNull(dictionaryItem) && !loading) {
                setErrorMessage('Не смогли получить элемент справочника для обновления')
                return;
            }
            loadPossibleValues(metadata.fields)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [action, dictionaryItem]);

        if (hasValue(errorMessage)) {
            return (<Content>
                <TableNoResults title={errorMessage}/>
            </Content>);
        }
        return (
            <Content>
                <DictionaryHeader mainInscription={`${t(metadata.dictionaryTitle)}${pageTitle}`}
                                  backToUrl={getBackToUrl()}/>
                <Form handleSubmit={handleSubmit} submitForm={submitForm}>
                    {Object.entries(metadata.fields)
                        .filter(([, value]) => value.hasOwnProperty('formElement') && value.hasOwnProperty('order'))
                        .sort(([, a], [, b]) => a.order - b.order)
                        .map((field, index) => {
                            const fieldName = field[0];
                            switch (field[1].formElement) {
                                case HIDDEN:
                                    return (<HiddenInput key={index} fieldName={field[1].title} register={register}/>);
                                case FORM_INPUT:
                                    return (<TextInputFormItem key={index} errors={errors} fieldName={field[1].title}
                                                               placeholder={t(field[1].placeholder)}
                                                               register={register}
                                                               hasValue={isFieldFilled(getValues, field[1].title)}
                                                               tabIndex={field.order}
                                                               onBlurFunction={getOnBlurFunction()}/>);
                                case FORM_NUMBER_INPUT:
                                    return <NumberInputFormItem key={index} fieldName={field[1].title} register={register}
                                                                placeholder={t(field[1].placeholder)}
                                                                onBlurFunction={getOnBlurFunction()}
                                                                tabIndex={field.order}
                                                                hasValue={isFieldFilled(getValues, field[1].title)}
                                                                errors={errors}/>;
                                case FORM_TEXTAREA:
                                    return (<TextAreaFormItem key={index} fieldName={field[1].title}
                                                              placeholder={t(field[1].placeholder)}
                                                              register={register} tabIndex={field[1].order}
                                                              action={action} errors={errors}
                                                              hasValue={isFieldFilled(getValues, field[1].title)}/>);
                                case FORM_RADIO_SET:
                                    return <RadioSetFormItem key={index} register={register} fieldName={field[1].title}
                                                             radioSetTitle={t(field[1].placeholder)}
                                                             options={possibleValues[fieldName]} required={false}
                                                             needToStore={true} errors={errors}/>;
                                case FORM_CHECKBOX_SET:
                                    return <CheckboxSetFormItem key={index} options={possibleValues[fieldName]}
                                                                register={register} errors={errors}
                                                                field={field[1]}
                                                                getValues={getValues} action={action}
                                                                headerTitle={t(field[1].placeholder)}/>
                                default:
                                    return <></>;
                            }
                        })
                    }
                    <SubmitSection hasFormError={error} errorMessage={errorMessage} isFormLoading={loading}
                                   submitLabel={t("button.save")} isSubmitDisabled={disabledSubmit}/>
                </Form>
            </Content>
        );
    }
;

export default DictionaryTranslations;