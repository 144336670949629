import React, {useEffect, useState} from 'react';
import {getAllRecordDataById} from "../../../service/dictionaryService";
import {EMPTY} from "../../../app/const/appConst";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import Content from "../Content";
import {hasValue, isEmptyOrNull} from "../../../app/helper/commonHelper";
import TableNoResults from "../table/TableNoResults";
import Spinner from "../Spinner";
import {get} from "lodash";
import {DICTIONARY_SET, ENUM, ENUM_SET} from "../form/helper/formConstants";
import InformationBlock from "../ui/text/informationblock/InformationBlock";
import DictionaryHeader from "./DictionaryHeader";

/**
 * Компонент просмотра элемента справочника
 */
const DictionaryElementView = ({metadata, previousUrl = null}) => {
    const ORDER_FIELD_PROPERTY = 'order';

    const params = useParams();
    const token = useSelector(state => state.userReducer.token);
    const {t} = useTranslation();
    const [dictionaryItem, setDictionaryItem] = useState({});
    const [errorMessage, setErrorMessage] = useState(EMPTY);
    const [loading, setIsLoading] = useState(false);
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);

    function showActionElements() {
        return isAdmin || isModerator;
    }

    function getItemInfo() {
        setIsLoading(true);
        const response = getAllRecordDataById(Number(params.id), metadata.backControllerName, token);
        response.then((resp) => {
            const data = resp.data.data;
            setDictionaryItem(data);
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        })
        setIsLoading(false);
    }

    function filterAndSortFields(metadata) {
        return Object.entries(metadata.fields)
            .filter(([, value]) => value.hasOwnProperty(ORDER_FIELD_PROPERTY) && value.visibleField !== false)
            .sort(([, a], [, b]) => a.order - b.order)
            .reduce((acc, [key, value]) => {
                acc[key] = value;
                return acc;
            }, {});
    }

    function formInfoMap() {
        const infoMap = [];
        if (isEmptyOrNull(metadata) || isEmptyOrNull(dictionaryItem)) {
            return;
        }
        Object.entries(filterAndSortFields(metadata)).forEach(field => {
            const dictionaryValue = getFieldValue(field[1].type, field[1].objectPath, dictionaryItem);
            if (isEmptyOrNull(dictionaryValue)) {
                return;
            }
            infoMap.push({
                title: t(field[1].placeholder),
                value: dictionaryValue,
                hasValue: true
            });
        })
        return infoMap;
    }

    function getFieldValue(fieldType, objectPath, dictionaryItem) {
        const value = get(dictionaryItem, objectPath, {});
        switch (fieldType) {
            case ENUM_SET:
                return value.map(el => `— ${el.name}`).join('\n');
            case ENUM:
                return value.name;
            case DICTIONARY_SET:
                return value.sort((a, b) => a.name.localeCompare(b.name))
                    .map(el => `${el.name}`).join('\n');
            default:
                return value;
        }
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        getItemInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isEmptyOrNull(dictionaryItem)) {
            return;
        }
        formInfoMap(metadata, dictionaryItem);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dictionaryItem])

    if (hasValue(errorMessage)) {
        return (<Content>
            <TableNoResults title={errorMessage}/>
        </Content>);
    }

    return (
        <Content>
            {loading ? <Spinner/> :
                <>
                    <DictionaryHeader topInscription={t(metadata.dictionaryTitle)}
                                      mainInscription={get(dictionaryItem, metadata.fields.name.objectPath, EMPTY)}
                                      backToUrl={isEmptyOrNull(previousUrl) ? null : previousUrl}
                                      showEditAction={showActionElements()}
                                      goToEditUrl={`/${metadata.backControllerName}/${dictionaryItem.id}/edit`}
                                      dictionaryItem={dictionaryItem}/>
                    <InformationBlock infoMap={formInfoMap()} withBorders={false}/>
                </>
            }
        </Content>
    );
};

export default DictionaryElementView;