import React from 'react';
import {hasValue} from "../../../../app/helper/commonHelper";

/**
 * Превью раздела о породе.
 * В ней содержится информация, которая не сворачивается
 */
const BreedInfoBlockPreview = ({title, children}) => {
    return (
        <div className="breed-info-block__preview">
            {hasValue(title) ?
                <div className="breed-info-block__header">
                    <span>{title}</span>
                </div> : null}
            {children}
        </div>
    );
};

export default BreedInfoBlockPreview;