import React from 'react';
import {hasValue, isEmptyOrNull} from "../../../app/helper/commonHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faPen} from "@fortawesome/free-solid-svg-icons/faPen";
import {useNavigate} from "react-router";

/**
 * Заголовок справочника с возможностью вернуться к определенному URL
 */
const DictionaryHeader = ({
                              mainInscription,
                              topInscription = null,
                              backToUrl = null,
                              showEditAction = false,
                              goToEditUrl = null,
                              dictionaryItem = null
                          }) => {
    const navigate = useNavigate();
    return (
        <div className="dictionary-element-header__wrapper">
            <div className="dictionary-element-header">
                {
                    isEmptyOrNull(topInscription) ? null :
                        <div className="dictionary-title">
                            <span>{topInscription}</span>
                        </div>
                }

                {
                    isEmptyOrNull(backToUrl) ? null :
                        <FontAwesomeIcon icon={faArrowLeft} className="fa-arrow-left"
                                         onClick={() => navigate(backToUrl)}/>
                }
                <span>{mainInscription}</span>
                {showEditAction && hasValue(goToEditUrl) ?
                    <FontAwesomeIcon icon={faPen} className="fa-dictionary-edit"
                                     onClick={() =>
                                         navigate(goToEditUrl,
                                             {state: {dictionaryEditingItem: dictionaryItem}})}/> : null}
            </div>
        </div>
    );
};

export default DictionaryHeader;