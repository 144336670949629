import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";
import {EMPTY} from "../../../../../app/const/appConst";
import {hasValue} from "../../../../../app/helper/commonHelper";
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons";

/**
 * Строка из контента информационного блока
 */
const InformationBlockItem = ({title, info, disabled = false, onClickPath = null, description = EMPTY}) => {
    const navigate = useNavigate();
    const isMobile = useSelector(state => state.mobileReducer.isMobile);
    return (
        <div className={`information-block-content__body-item ${disabled ? 'disabled' : EMPTY}`}
             onClick={() => onClickPath === null ? null : navigate(onClickPath)}>
            <div className="item-title">
                <span>{title}</span>
            </div>
            <div className="item-content">
                <span>{info}</span>
                {hasValue(description) ? <FontAwesomeIcon icon={faCircleInfo} className="fa-circle-info"/> : null}
            </div>
            {isMobile ? null :
                <div className="item-action">
                    <FontAwesomeIcon icon={faAngleRight} className='fa-angle-right'/>
                </div>}
        </div>
    );
};

export default InformationBlockItem;