import React from 'react';
import {useTranslation} from "react-i18next";
import BreedInfoBlock from "../../breedinfo/BreedInfoBlock";
import BreedInfoBlockPreview from "../../breedinfo/BreedInfoBlockPreview";
import InformationBlock from "../../../../../components/common/ui/text/informationblock/InformationBlock";
import {hasValue, isEmptyOrNull} from "../../../../../app/helper/commonHelper";
import {Link} from "react-router-dom";
import {ANIMAL_CLUBS_METADATA} from "../../../../adminpanel/dictionaries/metadata/animalClubMetadata";

/**
 * Блок "Базовая информация" на странице просмотра породы
 */
const BreedBasicInfoBlock = ({data}) => {
    const {t} = useTranslation();

    function getMentioned() {
        if (hasValue(data.firstMention)) {
            return (<span className="breed__first-mentioned">{data.firstMention}</span>);
        }
        return null;
    }

    function getOrigin() {
        if (hasValue(data.origin)) {
            return (<span className="breed__origin_country">{data.origin}</span>);
        }
        return null;
    }

    function formClubInfo(clubs) {
        if (isEmptyOrNull(clubs)) {
            return null;
        }
        return <>
            {Object.values(clubs).map(el => {
                return <Link key={el.code} to={`/${ANIMAL_CLUBS_METADATA.backControllerName}/${el.id}`}
                             className="goto__link">
                    {el.name}
                </Link>
            })}
        </>;
    }

    function getBasicInfoMap() {
        const infoMap = [];
        if (hasValue(data.globalPresence)) {
            infoMap.push({
                title: t('page.breed.section.basic.globalPresence'),
                value: data.globalPresence,
                // description: 'порода встречается в большинстве стран мира',
                hasValue: true
            });
        }
        if (hasValue(data.breedingPurposes)) {
            infoMap.push({
                title: t('page.breed.section.basic.primaryPurposes'),
                value: data.breedingPurposes.join(', '),
                hasValue: true
            });
        }
        if (hasValue(data.clubs)) {
            infoMap.push({
                title: t('page.breed.section.basic.clubs'),
                value: formClubInfo(data.clubs),
                hasValue: true
            });
        }
        if (hasValue(data.culturalSignificance)) {
            infoMap.push({
                title: t('page.breed.section.basic.culturalSignificance'),
                value: data.culturalSignificance,
                hasValue: true
            });
        }
        if (hasValue(data.breedHistory)) {
            infoMap.push({
                title: t('page.breed.section.basic.breedHistory'),
                value: data.breedHistory,
                hasValue: true
            });
        }
        if (hasValue(data.facts)) {
            infoMap.push({
                title: t('page.breed.section.basic.facts'),
                value: data.facts.map(fact => `— ${fact}`).join('\n'),
                hasValue: true
            });
        }
        return infoMap;
    }

    return (
        <BreedInfoBlock>
            <BreedInfoBlockPreview>
                <div className="breed-primary-info">
                    {getMentioned()}
                    {getOrigin()}
                </div>
                <InformationBlock infoMap={getBasicInfoMap()} withBorders={false}/>
            </BreedInfoBlockPreview>
        </BreedInfoBlock>
    );
};

export default BreedBasicInfoBlock;