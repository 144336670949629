import React from 'react';
import FormItem from "./FormItem";
import FormError from "./FormError";
import CheckboxSet from "../checkboxset/CheckboxSet";
import {isEmptyOrNull} from "../../../../../../app/helper/commonHelper";

/**
 * Компонент формы список чекбоксов
 */
const CheckboxSetFormItem = ({options, register, field, action, getValues, headerTitle, errors = null}) => {
    return (
        <FormItem>
            <CheckboxSet options={options}
                         register={register}
                         field={field}
                         getValues={getValues} action={action}
                         headerTitle={headerTitle}/>
            {errors == null || isEmptyOrNull(field) ?
                null : errors[field.title] ?
                    <FormError title={errors[field.title].message}/> : null}
        </FormItem>
    );
};

export default CheckboxSetFormItem;