import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import BreedService from "./api/BreedService";
import Content from "../../components/common/Content";
import {useTranslation} from "react-i18next";
import BreedInfo from "./components/breedinfo/BreedInfo";
import {isEmptyOrNull} from "../../app/helper/commonHelper";
import {createImageFromBlob} from "../../app/helper/imageHelper";

/**
 * Информация о породе питомца
 */
const Breed = () => {
    const CONTENT_DISPOSITION_HEADER = 'content-disposition';
    const {i18n} = useTranslation();
    const params = useParams();
    const [img, setImg] = useState({});
    const [breed, setBreed] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isImgLoading, setIsImgLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function getBreedInfo() {
        setIsLoading(true)
        const response = BreedService.showBreed(Number(params.id));

        response.then((resp) => {
            const data = resp.data.data;
            setBreed(data)
        }, (error) => {
            setErrorMessage(error.response?.data?.messages ?
                error.response?.data?.messages?.ERROR[0] : error.message)
        })
        setIsLoading(false)
    }

    function getImage() {
        setIsImgLoading(true)
        if (isEmptyOrNull(breed)) {
            return;
        }
        const response = BreedService.getImageByBreedCode(breed.code);
        response.then((resp) => {
            setImg(createImageFromBlob(resp.data, resp.headers[CONTENT_DISPOSITION_HEADER]));
        })
        setIsImgLoading(false)
    }

    /**
     * Получаем инфо о породе
     */
    useEffect(() => {
        window.scrollTo({top: 0, left: 0});
        getBreedInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language])

    /**
     * Подгружаем картинку
     */
    useEffect(() => {
        if (isEmptyOrNull(breed)) {
            return
        }
        //чтобы не перезагружать картинку
        if (Object.keys(img).length === 0) {
            getImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breed])

    if (isEmptyOrNull(breed)) {
        return <Content> </Content>
    }

    return (
        <Content>
            <BreedInfo breedInfo={breed}
                       image={img}
                       errorMessage={errorMessage}
                       isLoading={isLoading}
                       isImgLoading={isImgLoading}/>
        </Content>
    );
};

export default Breed;