import React from "react";
import Breeds from "../../../pages/breeds/Breeds";
import {Route, Routes, useLocation} from "react-router-dom";
import {Navigate} from "react-router";
import Profile from "../../../pages/profile/Profile";
import Breed from "../../../pages/breeds/Breed";
import LoginPage from "../../../pages/auth/login/LoginPage";
import RegistrationPage from "../../../pages/auth/registration/RegistrationPage";
import ModeratorRoute from "./ModeratorRoute";
import Index from "../../../pages/index/Index";
import NewBreed from "../../../pages/breeds/NewBreed";
import BreedTranslations from "../../../pages/breeds/BreedTranslations";
import PrivateRoute from "./PrivateRoute";
import NewPet from "../../../pages/pets/newpet/components/NewPet";
import {
    NEW_PET_STEP_FIVE_PATH,
    NEW_PET_STEP_FOUR_PATH,
    NEW_PET_STEP_ONE_PATH,
    NEW_PET_STEP_THREE_PATH,
    NEW_PET_STEP_TWO_PATH
} from "../../../pages/pets/newpet/helpers/petFormConstants";
import Pet from "../../../pages/pets/viewpet/components/Pet";
import PetPhoto from "../../../pages/pets/viewpet/components/updateparts/PetPhoto";
import PetName from "../../../pages/pets/viewpet/components/updateparts/PetName";
import PetBreed from "../../../pages/pets/viewpet/components/updateparts/PetBreed";
import PetGender from "../../../pages/pets/viewpet/components/updateparts/PetGender";
import PetBirthDate from "../../../pages/pets/viewpet/components/updateparts/PetBirthDate";
import PetChipNumber from "../../../pages/pets/viewpet/components/updateparts/PetChipNumber";
import PetTattooNumber from "../../../pages/pets/viewpet/components/updateparts/PetTattooNumber";
import PetDistinctiveFeatures from "../../../pages/pets/viewpet/components/updateparts/PetDistinctiveFeatures";
import PetLivingConditions from "../../../pages/pets/viewpet/components/updateparts/PetLivingConditions";
import PetLivingEnvironment from "../../../pages/pets/viewpet/components/updateparts/PetLivingEnvironment";
import ResetPassword from "../../../pages/auth/resetpassword/ResetPassword";
import UserPhoto from "../../../pages/profile/parts/UserPhoto";
import UserName from "../../../pages/profile/parts/UserName";
import UserLastname from "../../../pages/profile/parts/UserLastname";
import ActivateUser from "../../../pages/auth/activate/ActivateUser";
import Dashboard from "../../../pages/adminpanel/dashboard/Dashboard";
import UsersPanel from "../../../pages/adminpanel/userpanel/UsersPanel";
import Dictionaries from "../../../pages/adminpanel/dictionaries/Dictionaries";
import AdminRoute from "./AdminRoute";
import Continents from "../../../pages/adminpanel/dictionaries/geo/continents/Continents";
import Countries from "../../../pages/adminpanel/dictionaries/geo/countries/Countries";
import CountryTranslations from "../../../pages/adminpanel/dictionaries/geo/countries/CountryTranslations";
import ServiceInfo from "../../../pages/adminpanel/serviceinfo/ServiceInfo";
import DictionaryElementView from "../dictionary/DictionaryElementView";
import {ANIMAL_CLUBS_METADATA} from "../../../pages/adminpanel/dictionaries/metadata/animalClubMetadata";
import DictionaryTranslations from "../dictionary/DictionaryTranslations";
import usePreviousLocation from "../hooks/usePreviousLocation";
import Dictionary from "../dictionary/Dictionary";

const AppRouter = () => {
    const location = useLocation();
    const previousUrl = usePreviousLocation();

    return (
        <Routes>
            {/*Публичные маршруты*/}
            <Route path="/" element={<Index/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/registration" element={<RegistrationPage/>}/>
            <Route path="/reset-password/:code" element={<ResetPassword/>}/>
            <Route path="/activate/:code" element={<ActivateUser/>}/>
            <Route path="/breeds" element={<Breeds/>}/>
            <Route path="/breeds/:id" element={<Breed/>}/>
            <Route path="/animal-clubs/:id/*"
                   element={<DictionaryElementView previousUrl={previousUrl} metadata={ANIMAL_CLUBS_METADATA}/>}/>
            <Route path="*"
                   element={<Navigate to="/" replace/>}/>
            {/*Приватные маршруты*/}
            <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>}/>
            <Route path="/profile/photo" element={<PrivateRoute><UserPhoto/></PrivateRoute>}/>
            <Route path="/profile/name" element={<PrivateRoute><UserName/></PrivateRoute>}/>
            <Route path="/profile/lastname" element={<PrivateRoute><UserLastname/></PrivateRoute>}/>
            <Route path={NEW_PET_STEP_ONE_PATH} element={<PrivateRoute><NewPet/></PrivateRoute>}/>
            <Route path={NEW_PET_STEP_TWO_PATH} element={<PrivateRoute><NewPet/></PrivateRoute>}/>
            <Route path={NEW_PET_STEP_THREE_PATH} element={<PrivateRoute><NewPet/></PrivateRoute>}/>
            <Route path={NEW_PET_STEP_FOUR_PATH} element={<PrivateRoute><NewPet/></PrivateRoute>}/>
            <Route path={NEW_PET_STEP_FIVE_PATH} element={<PrivateRoute><NewPet/></PrivateRoute>}/>
            <Route path="/pets/:id" element={<PrivateRoute><Pet locationKey={location.key}/></PrivateRoute>}/>
            <Route path="/pets/:id/photo" element={<PrivateRoute><PetPhoto/></PrivateRoute>}/>
            <Route path="/pets/:id/name" element={<PrivateRoute><PetName/></PrivateRoute>}/>
            <Route path="/pets/:id/breed" element={<PrivateRoute><PetBreed/></PrivateRoute>}/>
            <Route path="/pets/:id/gender" element={<PrivateRoute><PetGender/></PrivateRoute>}/>
            <Route path="/pets/:id/birthdate" element={<PrivateRoute><PetBirthDate/></PrivateRoute>}/>
            <Route path="/pets/:id/chip-number" element={<PrivateRoute><PetChipNumber/></PrivateRoute>}/>
            <Route path="/pets/:id/tattoo-number" element={<PrivateRoute><PetTattooNumber/></PrivateRoute>}/>
            <Route path="/pets/:id/distinctive-features"
                   element={<PrivateRoute><PetDistinctiveFeatures/></PrivateRoute>}/>
            <Route path="/pets/:id/living-environment" element={<PrivateRoute><PetLivingEnvironment/></PrivateRoute>}/>
            <Route path="/pets/:id/living-conditions" element={<PrivateRoute><PetLivingConditions/></PrivateRoute>}/>
            {/*Для модераторов*/}
            <Route path="/breeds/new" element={<ModeratorRoute><NewBreed/></ModeratorRoute>}/>
            <Route path="/breeds/:id/edit" element={<ModeratorRoute><BreedTranslations/></ModeratorRoute>}/>
            {/*Админка*/}
            <Route path="/panel/dashboard" element={<ModeratorRoute><Dashboard/></ModeratorRoute>}/>
            <Route path="/panel/users" element={<AdminRoute><UsersPanel/></AdminRoute>}/>
            <Route path="/panel/dictionaries" element={<ModeratorRoute><Dictionaries/></ModeratorRoute>}/>
            <Route path="/panel/service-info" element={<ModeratorRoute><ServiceInfo/></ModeratorRoute>}/>
            {/*Справочники*/}
            <Route path="/panel/dictionaries/continents" element={<ModeratorRoute><Continents/></ModeratorRoute>}/>
            <Route path="/panel/dictionaries/countries" element={<ModeratorRoute><Countries/></ModeratorRoute>}/>
            <Route path="/panel/dictionaries/countries/:id/edit"
                   element={<ModeratorRoute><CountryTranslations/></ModeratorRoute>}/>
            <Route path="/panel/dictionaries/countries/new"
                   element={<ModeratorRoute><CountryTranslations/></ModeratorRoute>}/>

            <Route path="/animal-clubs/*"
                   element={<ModeratorRoute><Dictionary metadata={ANIMAL_CLUBS_METADATA}/></ModeratorRoute>}/>
            <Route path="/animal-clubs/new"
                   element={<ModeratorRoute><DictionaryTranslations
                       metadata={ANIMAL_CLUBS_METADATA}/></ModeratorRoute>}/>
            <Route path="/animal-clubs/:id/edit"
                   element={<ModeratorRoute><DictionaryTranslations
                       metadata={ANIMAL_CLUBS_METADATA}/></ModeratorRoute>}/>
        </Routes>
    );
};

export default AppRouter;