import React from 'react';
import {hasValue, isEmptyOrNull} from "../../../../app/helper/commonHelper";
import RadioSet from "../../../../components/common/form/parts/inputs/RadioSet";
import {BREED_METADATA} from "../../helpers/breedFormConstants";
import TextAreaFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextAreaFormItem";
import {isFieldFilled, storeItem} from "../../../../components/common/form/helper/formHelper";
import {ACTION_ADD} from "../../../../components/common/form/helper/formConstants";
import TextInputFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextInputFormItem";
import {useTranslation} from "react-i18next";
import CheckboxSet from "../../../../components/common/form/parts/inputs/checkboxset/CheckboxSet";

/**
 * Часть формы по добавлению породы "Базовая информация"
 */
const BreedBasicInfoFormSection = ({errors, register, getValues, action, basicInfoEnums, animalClubsOptions = [],
                                       animalKind}) => {
    const {t} = useTranslation();

    function getAnimalClubsForAnimalKind(animalClubsOptions, animalKind) {
        if (isEmptyOrNull(animalKind) || isEmptyOrNull(animalClubsOptions)) {
            return []
        }
        return Object.values(animalClubsOptions)
            .filter(value => value.animalKind.id === animalKind);

    }

    return (
        <div className="breed-section__wrapper">
            <div className="breed-section">
                <div className="breed-section__header">
                    <h2>{t('page.breed.section.basic.title')}</h2>
                </div>
                <TextInputFormItem errors={errors} fieldName={BREED_METADATA.fields.firstMention.title}
                                   placeholder={t('page.breed.section.basic.firstMention')}
                                   register={register}
                                   hasValue={isFieldFilled(getValues, BREED_METADATA.fields.breedTitle.title)}
                                   tabIndex={BREED_METADATA.fields.firstMention.tabIndex}
                                   onBlurFunction={action === ACTION_ADD ? storeItem : null}/>
                <TextInputFormItem errors={errors} fieldName={BREED_METADATA.fields.culturalSignificance.title}
                                   placeholder={t('page.breed.section.basic.culturalSignificance')}
                                   register={register}
                                   hasValue={isFieldFilled(getValues, BREED_METADATA.fields.culturalSignificance.title)}
                                   tabIndex={BREED_METADATA.fields.culturalSignificance.tabIndex}
                                   onBlurFunction={action === ACTION_ADD ? storeItem : null}/>
                <CheckboxSet options={getAnimalClubsForAnimalKind(animalClubsOptions, animalKind)} register={register}
                             field={BREED_METADATA.fields.animalClubs}
                             action={action} getValues={getValues}
                             headerTitle={t('page.breed.section.basic.clubs')}/>

                {hasValue(basicInfoEnums) ?
                    <>
                        <div className="breed-section__extended_params">
                            <CheckboxSet options={basicInfoEnums.breedingPurposes} register={register}
                                         field={BREED_METADATA.fields.breedingPurposes}
                                         getValues={getValues} action={action}
                                         headerTitle={t('page.breed.section.basic.primaryPurposes')}/>
                            <RadioSet register={register} fieldName={BREED_METADATA.fields.historicalPeriod.title}
                                      radioSetTitle={t('page.breed.section.basic.historicalPeriod')}
                                      options={basicInfoEnums.historicalPeriods} required={false} needToStore={true}/>
                            <RadioSet register={register} fieldName={BREED_METADATA.fields.globalPresence.title}
                                      radioSetTitle={t('page.breed.section.basic.globalPresence')}
                                      options={basicInfoEnums.globalPresences} required={false} needToStore={true}/>
                        </div>
                    </> :
                    null
                }
                <TextAreaFormItem fieldName={BREED_METADATA.fields.breedHistory.title}
                                  placeholder={t("form.newBreed.breedHistory")}
                                  register={register} tabIndex={BREED_METADATA.fields.breedHistory.tabIndex}
                                  action={action}
                                  hasValue={isFieldFilled(getValues, BREED_METADATA.fields.breedHistory.title)}/>
            </div>
        </div>
    );
};

export default BreedBasicInfoFormSection;
