import React, {useEffect, useState} from 'react';
import {hasValue, isEmptyOrNull} from "../../../../../../app/helper/commonHelper";
import {ACTION_UPDATE, DICTIONARY_SET, ENUM_SET} from "../../../helper/formConstants";
import {EMPTY} from "../../../../../../app/const/appConst";
import EnumCheckboxSet from "./EnumCheckboxSet";
import DictionaryCheckboxSet from "./DictionaryCheckboxSet";

/**
 * Компонент множественного выбора из списка checkbox'ов
 */
const CheckboxSet = ({options, register, field, action, getValues, headerTitle}) => {
    const [selectedItems, setSelectedItems] = useState(new Set());
    const fieldName = hasValue(field) ? field.title : EMPTY;
    const fieldType = hasValue(field) ? field.type : EMPTY;
    let fieldValues = getValues(fieldName);

    useEffect(() => {
        if (ACTION_UPDATE === action) {
            if (isEmptyOrNull(getValues(fieldName))) {
                return;
            }
            const defaultValues = Object.entries(getValues(fieldName))
                .filter(([, value]) => value !== false)
                .map(([key]) => key);
            setSelectedItems(new Set(defaultValues));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldValues]);

    function handleCheckboxChange(code) {
        setSelectedItems(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(code)) {
                newSelected.delete(code);
            } else {
                newSelected.add(code);
            }
            return newSelected;
        });
    }

    if (isEmptyOrNull(options)) {
        return null;
    }
    return (
        <div className="checkbox multiselect">
            <div className='checkbox-header'>
                <span>{headerTitle}</span>
            </div>
            {ENUM_SET === fieldType ?
                <EnumCheckboxSet enumValues={options} fieldName={fieldName} selectedItems={selectedItems}
                                 register={register} handleCheckboxChange={handleCheckboxChange}/> :
                null}

            {DICTIONARY_SET === fieldType ?
                <DictionaryCheckboxSet dictionaryValues={options} fieldName={fieldName} selectedItems={selectedItems}
                                       register={register} handleCheckboxChange={handleCheckboxChange}/> :
                null}
        </div>
    );
};

export default CheckboxSet;