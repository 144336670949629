import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {useNavigate} from "react-router";
import {EMPTY} from "../../app/const/appConst";
import {isEmptyOrNull} from "../../app/helper/commonHelper";

/**
 * Заголовок + текстовое описание для изменяемого элемента
 */
const UpdatableElementPreview = ({title, content = EMPTY, backTo}) => {
    const navigate = useNavigate();
    return (
        <div className="updated-element__wrapper">
            <div className="updated-element__header">
                {isEmptyOrNull(backTo) ? null :
                    <FontAwesomeIcon icon={faArrowLeft} className="fa-arrow-left"
                                     onClick={() => navigate(`${backTo}`)}/>
                }
                <span>{title}</span>
            </div>

            {content === '' ? null :
                <div className="updated-element__content">
                    <span>{content}</span>
                </div>
            }
        </div>
    );
};

export default UpdatableElementPreview;