import React from 'react';
import RangeFormItem from "../../../../components/common/form/parts/inputs/wrappers/RangeFormItem";
import {BREED_METADATA} from "../../helpers/breedFormConstants";
import {hasValue} from "../../../../app/helper/commonHelper";
import {ACTION_ADD} from "../../../../components/common/form/helper/formConstants";
import {storeItem} from "../../../../components/common/form/helper/formHelper";
import {useTranslation} from "react-i18next";

/**
 * Часть формы по работе с Породой - "Гендерные параметры"
 */
const BreedGenderParams = ({action, allValues, register, errors}) => {
    const {t} = useTranslation();

    return (
        <div className="gender-form-params__wrapper">
            <div className="gender-form-params male">
                <div className="gender-form-params__header">
                    <span>Для самцов</span>
                </div>
                {/*следующие два диапазона относятся к сущности appearance*/}
                <RangeFormItem rangeFieldId={BREED_METADATA.fields.maleSizeRangeId.title}
                               title={t("form.newBreed.size")}
                               fieldNameFrom={BREED_METADATA.fields.maleSizeFrom.title}
                               fieldNameTo={BREED_METADATA.fields.maleSizeTo.title}
                               hasValueFrom={hasValue(allValues[BREED_METADATA.fields.maleSizeFrom.title])}
                               hasValueTo={hasValue(allValues[BREED_METADATA.fields.maleSizeTo.title])}
                               tabIndexFrom={BREED_METADATA.fields.maleSizeFrom.tabIndex}
                               tabIndexTo={BREED_METADATA.fields.maleSizeTo.tabIndex}
                               errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

                <RangeFormItem rangeFieldId={BREED_METADATA.fields.maleWeightRangeId.title}
                               title={t("form.newBreed.weight")}
                               fieldNameFrom={BREED_METADATA.fields.maleWeightFrom.title}
                               fieldNameTo={BREED_METADATA.fields.maleWeightTo.title}
                               hasValueFrom={hasValue(allValues[BREED_METADATA.fields.maleWeightFrom.title])}
                               hasValueTo={hasValue(allValues[BREED_METADATA.fields.maleWeightTo.title])}
                               tabIndexFrom={BREED_METADATA.fields.maleWeightFrom.tabIndex}
                               tabIndexTo={BREED_METADATA.fields.maleWeightTo.tabIndex}
                               errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

                {/*следующий диапазон относятся к сущности health */}
                <RangeFormItem rangeFieldId={BREED_METADATA.fields.maleLifeRangeId.title}
                               title={t("form.newBreed.life")}
                               fieldNameFrom={BREED_METADATA.fields.maleLifeFrom.title}
                               fieldNameTo={BREED_METADATA.fields.maleLifeTo.title}
                               hasValueFrom={hasValue(allValues[BREED_METADATA.fields.maleLifeFrom.title])}
                               hasValueTo={hasValue(allValues[BREED_METADATA.fields.maleLifeTo.title])}
                               tabIndexFrom={BREED_METADATA.fields.maleLifeFrom.tabIndex}
                               tabIndexTo={BREED_METADATA.fields.maleLifeTo.tabIndex} errors={errors}
                               register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>
            </div>


            <div className="gender-form-params female">
                <div className="gender-form-params__header">
                    <span>Для самок</span>
                </div>
                {/*следующие два диапазона относятся к сущности appearance*/}
                <RangeFormItem rangeFieldId={BREED_METADATA.fields.femaleSizeRangeId.title}
                               title={t("form.newBreed.size")}
                               fieldNameFrom={BREED_METADATA.fields.femaleSizeFrom.title}
                               fieldNameTo={BREED_METADATA.fields.femaleSizeTo.title}
                               hasValueFrom={hasValue(allValues[BREED_METADATA.fields.femaleSizeFrom.title])}
                               hasValueTo={hasValue(allValues[BREED_METADATA.fields.femaleSizeTo.title])}
                               tabIndexFrom={BREED_METADATA.fields.femaleSizeFrom.tabIndex}
                               tabIndexTo={BREED_METADATA.fields.femaleSizeTo.tabIndex}
                               errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

                <RangeFormItem rangeFieldId={BREED_METADATA.fields.femaleWeightRangeId.title}
                               title={t("form.newBreed.weight")}
                               fieldNameFrom={BREED_METADATA.fields.femaleWeightFrom.title}
                               fieldNameTo={BREED_METADATA.fields.femaleWeightTo.title}
                               hasValueFrom={hasValue(allValues[BREED_METADATA.fields.femaleWeightFrom.title])}
                               hasValueTo={hasValue(allValues[BREED_METADATA.fields.femaleWeightTo.title])}
                               tabIndexFrom={BREED_METADATA.fields.femaleWeightFrom.tabIndex}
                               tabIndexTo={BREED_METADATA.fields.femaleWeightTo.tabIndex}
                               errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>

                {/*следующий диапазон относятся к сущности health */}
                <RangeFormItem rangeFieldId={BREED_METADATA.fields.femaleLifeRangeId.title}
                               title={t("form.newBreed.life")}
                               fieldNameFrom={BREED_METADATA.fields.femaleLifeFrom.title}
                               fieldNameTo={BREED_METADATA.fields.femaleLifeTo.title}
                               hasValueFrom={hasValue(allValues[BREED_METADATA.fields.femaleLifeFrom.title])}
                               hasValueTo={hasValue(allValues[BREED_METADATA.fields.femaleLifeTo.title])}
                               tabIndexFrom={BREED_METADATA.fields.femaleLifeFrom.tabIndex}
                               tabIndexTo={BREED_METADATA.fields.femaleLifeTo.tabIndex}
                               errors={errors} register={register}
                               onBlurFunction={action === ACTION_ADD ? storeItem : null}/>
            </div>
        </div>
    );
};

export default BreedGenderParams;