import React from 'react';
import {hasValue} from "../../../../app/helper/commonHelper";
import RadioSet from "../../../../components/common/form/parts/inputs/RadioSet";
import {BREED_METADATA} from "../../helpers/breedFormConstants";
import TextAreaFormItem from "../../../../components/common/form/parts/inputs/wrappers/TextAreaFormItem";
import {isFieldFilled} from "../../../../components/common/form/helper/formHelper";
import {useTranslation} from "react-i18next";

/**
 * Секция "Уход" формы по работе с породами
 */
const BreedCareFormSection = ({register, getValues, action, careEnums}) => {
    const {t} = useTranslation();
    return (
        <div className="breed-section__wrapper">
            <div className="breed-section">
                <div className="breed-section__header">
                    <h2>Уход</h2>
                </div>
                {hasValue(careEnums) ?
                    <>
                        <div className="breed-section__aggregated-mark">
                            <RadioSet register={register} fieldName={BREED_METADATA.fields.careLevel.title}
                                      radioSetTitle="Общая оценка"
                                      options={careEnums[BREED_METADATA.fields.careLevel.title]}
                                      required={true} needToStore={true}
                                      additionalClasses="wide"/>
                        </div>
                        <div className="breed-section__extended_params">
                            <RadioSet register={register} fieldName={BREED_METADATA.fields.careGrooming.title}
                                      radioSetTitle={t("page.breed.section.care.grooming")}
                                      options={careEnums.grooming} required={false} needToStore={true}/>
                            <RadioSet register={register} fieldName={BREED_METADATA.fields.careBathing.title}
                                      radioSetTitle={t("page.breed.section.care.bathing")}
                                      options={careEnums.bathing} required={false} needToStore={true}/>
                            <RadioSet register={register} fieldName={BREED_METADATA.fields.careEarCleaning.title}
                                      radioSetTitle={t("page.breed.section.care.earCleaning")}
                                      options={careEnums.earCleaning} required={false}
                                      needToStore={true}/>
                            <RadioSet register={register} fieldName={BREED_METADATA.fields.careEyeCleaning.title}
                                      radioSetTitle={t("page.breed.section.care.eyeCleaning")}
                                      options={careEnums.eyeCleaning} required={false}
                                      needToStore={true}/>
                            <RadioSet register={register} fieldName={BREED_METADATA.fields.careTeethCleaning.title}
                                      radioSetTitle={t("page.breed.section.care.teethCleaning")}
                                      options={careEnums.teethCleaning} required={false}
                                      needToStore={true}/>
                        </div>
                    </> :
                    null
                }
                <TextAreaFormItem fieldName={BREED_METADATA.fields.careDescription.title}
                                  placeholder={t("form.newBreed.careDescription")}
                                  register={register} tabIndex={BREED_METADATA.fields.careDescription.tabIndex}
                                  action={action}
                                  hasValue={isFieldFilled(getValues, BREED_METADATA.fields.careDescription.title)}/>
            </div>
        </div>
    );
};

export default BreedCareFormSection;