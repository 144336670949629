import React from 'react';
import {useSelector} from "react-redux";

/**
 * Заголовок таблицы справочников
 */
const DictionaryTableTitle = () => {
    const isAdmin = useSelector(state => state.userReducer.isAdmin);
    const isModerator = useSelector(state => state.userReducer.isModerator);

    if (!(isAdmin || isModerator)) {
        return (
            <div className="table-line table-title">
                <div className="table-line__cell cell-100">
                    <span className="table-line__description">Название</span>
                </div>
            </div>
        );
    }

    return (
        <div className="table-line table-title">
            <div className="table-line__cell cell-90">
                <span className="table-line__description">Название</span>
            </div>
            <div className="table-line__cell cell-10">
                <span className="table-line__description">Действия</span>
            </div>
        </div>
    );
};

export default DictionaryTableTitle;