import React from 'react';
import FormError from "./FormError";
import FormItem from "./FormItem";
import {EMPTY} from "../../../../../../app/const/appConst";
import RadioSet from "../RadioSet";

/**
 * Компонент формы
 */
const RadioSetFormItem = ({
                              register,
                              fieldName,
                              radioSetTitle,
                              options,
                              required,
                              additionalClasses = EMPTY,
                              needToStore = false,
                              errors = null
                          }) => {
    return (
        <FormItem>
            <RadioSet register={register} fieldName={fieldName}
                      radioSetTitle={radioSetTitle}
                      options={options}
                      required={required} needToStore={needToStore}
                      additionalClasses={additionalClasses}/>
                {errors == null ? null : errors[fieldName] ? <FormError title={errors[fieldName].message}/> : null}
        </FormItem>
    );
};

export default RadioSetFormItem;