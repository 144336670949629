import {useLocation} from "react-router-dom";
import {useEffect, useRef} from "react";

/**
 * Хук, который хранит предыдущий URL
 */
const usePreviousLocation = () => {
    const location = useLocation();
    const previousLocationRef = useRef(null);

    useEffect(() => {
        // Сохранение предыдущего местоположения
        previousLocationRef.current = location;
    }, [location]);

    return previousLocationRef.current;
};

export default usePreviousLocation;